$(document).ready(function () {

    if ($('.js-header').length){
        $(window).scroll(function () {
            if ($(document).scrollTop() > 50) {
                $('.js-header').addClass('active');
            }
            else {
                $('.js-header').removeClass('active');
            }
        });
    }
    
    

 /* LOCK / RELEASE SCROLL */
    function getScrollBarWidth() {
        let el = document.createElement("div");
        el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";
        document.body.appendChild(el);
        let width = el.offsetWidth - el.clientWidth;
        el.remove();
        return width;
    }
    window.scrollBarWidth = getScrollBarWidth();
    document.documentElement.style.setProperty('--scrollbarWidth', window.scrollBarWidth + "px");

    window.lockScroll = function () {
        let scrollTop = document.querySelector('html').scrollTop ? document.querySelector('html').scrollTop : document.querySelector('body').scrollTop;
        document.querySelector('html').classList.add('noscroll');
        document.querySelector('html').style.top = -scrollTop + 'px';
    }
    window.releaseScroll = function () {
        let scrollTop = parseInt(document.querySelector('html').style.top);
        document.querySelector('html').classList.remove('noscroll');
        document.querySelector('html,body').scrollTop = -scrollTop;
    }

    

/* MENU */
    $('.js-open-menu').on('click', function () {
        lockScroll();
        $('.overlay').fadeIn(200);
        $(this).parent().addClass('open');
        $('.js-menu').addClass('open');
    });

    $('.js-close-menu').on('click', function () {
        releaseScroll();
        $('.overlay').fadeOut(200);
        $(this).parent().removeClass('open');
        $('.js-menu').removeClass('open');
    });



// Fonction pour vérifier si l'élément est visible à l'écran
    function elementIsInView(elem) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }


    const swiper = new Swiper('.slider-1', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });

    const swiper2 = new Swiper('.slider-2', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

    });


});
